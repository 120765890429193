define("flaneur/utils/products-bulk-upload/index", ["exports", "flaneur/utils/products-bulk-upload/mappers", "flaneur/utils/products-bulk-upload/validations", "flaneur/enums/bulk-upload-error-status"], function (_exports, _mappers, _validations, _bulkUploadErrorStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateHeaders = validateHeaders;
  _exports.validateProducts = validateProducts;
  _exports.parseCsv = parseCsv;
  _exports.processCsv = processCsv;
  _exports.processImages = processImages;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ALLOWED_HEADERS = ['brand', 'name', 'description', 'price', 'quantity', 'size', 'material', 'color', 'other', 'sku', 'dimensions.length', 'dimensions.width', 'dimensions.height', 'care_instructions', 'gtin', 'images'];

  const getEmptyProduct = (boutique, properties = {}) => {
    return _objectSpread({
      brand: boutique.brand,
      boutiques: [{
        boutique: boutique._id,
        stock: true
      }],
      stock_lines: [],
      images: [],
      categories: []
    }, properties);
  };

  const getEmptyStockLine = (boutique, properties = {}) => {
    return _objectSpread({
      boutique: boutique._id,
      dimensions: {},
      variants: []
    }, properties);
  };

  const getStockLineErrorMessage = ({
    message
  }) => {
    const regex = /"[size|material|color|other]*"/g;

    const replacer = match => match.replace(/"/gi, '');

    return message.replace(regex, replacer);
  };

  async function getParsedProducts(data, boutique, onChangeStatus) {
    const products = [];

    for (let index = 0; index < data.length; index++) {
      const row = data[index]; // The first line of a .csv file is the "2"
      // considering the first is the header

      const csvLine = index + 2;
      onChangeStatus({
        current: index + 1,
        total: data.length
      });
      let product = products.findBy('name', row.name);

      if (Ember.isEmpty(product)) {
        product = getEmptyProduct(boutique, {
          csvLine
        });
        const productMapperKeys = Object.keys(_mappers.productMapper);

        for (const key of productMapperKeys) {
          await _mappers.productMapper[key](product, row[key], boutique);
        }

        products.addObject(product);
      }

      const stockLine = getEmptyStockLine(boutique, {
        csvLine
      });
      const stockLineMapperKeys = Object.keys(_mappers.stockLineMapper);

      for (const key of stockLineMapperKeys) {
        await _mappers.stockLineMapper[key](stockLine, row[key], boutique);
      }

      product.stock_lines.addObject(stockLine);
    }

    return products;
  }

  function validateProducts(products) {
    products.forEach(product => {
      product.errors = [];
      const valid = (0, _validations.validateProduct)(product);

      if (!valid) {
        product.errors.addObjects(_validations.validateProduct.errors.map(error => _objectSpread(_objectSpread({}, error), {}, {
          status: _bulkUploadErrorStatus.default.AWAITING,
          csvLine: product.csvLine
        })));
      }

      const validateStockLineUpload = product.stock_lines.length === 1 ? _validations.validateOneStockLine : _validations.validateMultipleStockLines;
      product.stock_lines.forEach((stockLine, index) => {
        const valid = validateStockLineUpload(stockLine);

        if (!valid) {
          product.errors.addObjects(validateStockLineUpload.errors.map(error => _objectSpread(_objectSpread({}, error), {}, {
            csvLine: stockLine.csvLine,
            stockLineIndex: index,
            status: _bulkUploadErrorStatus.default.AWAITING,
            message: getStockLineErrorMessage(error)
          })));
        }
      });
    });
  }

  function validateHeaders(data) {
    if (Ember.isEmpty(data)) {
      return false;
    }

    const headers = Object.keys(data[0]);
    return headers.every(header => header.length <= 0 || ALLOWED_HEADERS.includes(header));
  }

  function parseCsv(csv, papaparseOptions = {}) {
    return emberAutoImportDynamic("papaparse").then(module => module.default).then(Papa => new Ember.RSVP.Promise((resolve, reject) => {
      const options = _objectSpread({
        header: true,
        skipEmptyLines: 'greedy',
        dynamicTyping: true,
        complete: resolve,
        error: reject
      }, papaparseOptions);

      Papa.parse(csv, options);
    }));
  }

  async function processCsv(data, boutique, onChangeStatus = () => {}) {
    if (Ember.isEmpty(data)) {
      return {
        products: []
      };
    }

    onChangeStatus({
      current: 0,
      total: data.length
    });
    const products = await getParsedProducts(data, boutique, onChangeStatus);
    validateProducts(products);
    return {
      products
    };
  }

  async function processImages(products, onChangeStatus = () => {}) {
    const productsWithImages = products.filter(({
      images
    }) => Ember.isPresent(images));

    for (let index = 0; index < productsWithImages.length; index++) {
      onChangeStatus({
        current: index + 1,
        total: productsWithImages.length
      });
      await (0, _mappers.imagesMapper)(productsWithImages[index]);
    }
  }
});