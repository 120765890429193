define("flaneur/pods/components/nav-bar-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qtmQzMj5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"class\",\"route\"],[\"lp-logo\",\"index\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[14,\"src\",\"https://res.cloudinary.com/streethub/image/upload/c_scale,h_75/v1517223377/main/Trouva_2018_2x.png\"],[14,\"alt\",\"Trouva\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],null,[[\"class\",\"route\",\"model\"],[\"button button--secondary lp-login\",\"brand\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"    My retail panel\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"button button--secondary lp-login\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Boutique Log in\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"brand\",\"link-to\",\"isLoggedIn\",\"if\"]}",
    "moduleName": "flaneur/pods/components/nav-bar-buttons/template.hbs"
  });

  _exports.default = _default;
});