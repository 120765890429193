define("flaneur/pods/components/cloudinary-image/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    src: 'https://res.cloudinary.com/streethub/image/upload/v1448553226/security/placeholder-with-logo.png',
    width: 300,
    height: 300,
    cloudinaryUrlSegment: 'image/upload',
    fit: 'fill',
    transformedSrc: Ember.computed('src', 'width', 'height', 'fit', 'cloudinaryUrlSegment', function () {
      const src = this.src || '';
      const {
        cloudinaryUrlSegment,
        width,
        height,
        fit
      } = this;
      const transformation = `c_${fit},f_auto,w_${width},h_${height},q_auto`;
      return src.replace(cloudinaryUrlSegment, `${cloudinaryUrlSegment}/${transformation}`);
    })
  });

  _exports.default = _default;
});