define("flaneur/services/products-bulk-uploader", ["exports", "flaneur/utils/products-bulk-upload", "flaneur/utils/products-bulk-upload/validations", "flaneur/utils/file-size"], function (_exports, _productsBulkUpload, _validations, _fileSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    products: [],
    isUploadingCsv: false,
    isUploadingImages: false,
    hasUploadedCsv: false,
    hasInvalidFileType: false,
    hasInvalidHeaders: false,
    isUploading: Ember.computed.or('isUploadingCsv', 'isUploadingImages'),
    errors: Ember.computed('products', function () {
      return this.products.reduce((errors, product) => [...errors, ...(product.errors || [])], []).sortBy('csvLine');
    }),

    isUploadError({
      dataPath
    }) {
      return dataPath.match(_validations.dataPathRegExps.upload);
    },

    isAmendError({
      dataPath
    }) {
      return dataPath.match(_validations.dataPathRegExps.amend);
    },

    async importCsv(csvFile, boutique, onChangeStatus = () => {}) {
      const {
        name,
        lastModified
      } = csvFile;
      const fileSize = (0, _fileSize.getReadableFileSize)(csvFile);
      const fileExtension = (0, _fileSize.getFileExtension)(csvFile);
      this.setProperties({
        products: [],
        csvFileInfo: {
          name,
          lastModified,
          size: fileSize
        },
        isUploadingCsv: true,
        isUploadingImages: false,
        hasUploadedCsv: false,
        hasInvalidFileType: false,
        hasInvalidHeaders: false
      });
      const isValidFileType = fileExtension === 'csv' || csvFile.type === 'text/csv';

      if (!isValidFileType) {
        this.setProperties({
          isUploadingCsv: false,
          hasInvalidFileType: true
        });
        return;
      }

      const {
        data
      } = await (0, _productsBulkUpload.parseCsv)(csvFile);

      if (Ember.isEmpty(data)) {
        return [];
      }

      const valid = (0, _productsBulkUpload.validateHeaders)(data);

      if (!valid) {
        this.setProperties({
          isUploadingCsv: false,
          hasInvalidHeaders: true
        });
        return;
      }

      const {
        products
      } = await (0, _productsBulkUpload.processCsv)(data, boutique, onChangeStatus);
      this.set('products', products);
      const uploadErrors = this.errors.filter(error => this.isUploadError(error));
      const hasUploadErrors = Ember.isPresent(uploadErrors);
      this.setProperties({
        isUploadingCsv: false,
        hasUploadedCsv: hasUploadErrors
      });

      if (hasUploadErrors) {
        return;
      }

      this.set('isUploadingImages', true);
      await (0, _productsBulkUpload.processImages)(this.products, onChangeStatus);
      this.setProperties({
        isUploadingImages: false,
        hasUploadedCsv: true
      });
    },

    validate() {
      (0, _productsBulkUpload.validateProducts)(this.products);
    },

    reset() {
      this.setProperties({
        products: [],
        csvFileInfo: null,
        isUploadingCsv: false,
        isUploadingImages: false,
        hasUploadedCsv: false,
        hasInvalidFileType: false,
        hasInvalidHeaders: false
      });
    }

  });

  _exports.default = _default;
});