define("flaneur/pods/components/form-flows/shipment/order-details/component", ["exports", "flaneur/pods/components/form-flows/shipment/order-details/validations", "flaneur/models/product", "ember-concurrency", "flaneur/models/shipping-path", "flaneur/enums/incoterm", "@datadog/browser-rum", "flaneur/utils/incoterm", "flaneur/enums/shipment-protection-cover", "flaneur/utils/price"], function (_exports, _validations, _product, _emberConcurrency, _shippingPath, _incoterm, _browserRum, _incoterm2, _shipmentProtectionCover, _price) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    features: Ember.inject.service(),
    media: Ember.inject.service(),
    tagName: 'section',
    classNames: ['order-details'],
    shipment: null,
    showIncotermOptions: false,
    shipmentProtectionCover: _shipmentProtectionCover.default,
    showParcelProtectionOptions: false,
    insuranceTermsAndConditionsUrl: 'https://assets.trouva.com/image/upload/v1628262320/Retail%20Panel/quickship/QuickShip_Parcel_Protection_Terms.pdf',
    insuranceFindOutMoreUrl: 'https://trouvaboutiques.zendesk.com/hc/en-us/articles/4407199741713-Quickship-Save-on-courier-services-with-Quickship',
    boutique: Ember.computed.alias('brand.currentBoutique'),
    currency: Ember.computed.alias('boutique.currency'),
    incoterm: Ember.computed.alias('shipment.incoterm'),
    hasMoreThanOnePackageItem: Ember.computed.gt('shipment.items.length', 1),
    addAnotherProductDisabled: Ember.computed('shipment.items.@each.name', function () {
      const {
        items
      } = this.shipment;
      const lastItem = items.objectAt(items.length - 1);
      return Ember.isEmpty(lastItem.name);
    }),
    isEnhancedProtectionCoverSelected: Ember.computed.equal('selectedProtectionCover', _shipmentProtectionCover.default.ENHANCED),
    maximumInsurancePrice: Ember.computed('currency', 'shipment', function () {
      return (0, _price.formatPrice)(this.shipment.getMaximumInsurancePrice(this.currency));
    }),

    async init() {
      this._super(...arguments);

      this.set('selectedProtectionCover', !this.shipment.insurance ? _shipmentProtectionCover.default.STANDARD : _shipmentProtectionCover.default.ENHANCED);

      try {
        const {
          collection_address,
          delivery_address
        } = this.shipment;
        const {
          shipping_paths: shippingPaths
        } = await _shippingPath.default.find({
          source_country_code: collection_address.country_iso_alpha_3,
          destination_country_code: delivery_address.country_iso_alpha_3
        });
        const [shippingPath] = shippingPaths;
        const showIncotermOptions = (0, _incoterm2.allowIncotermCustomization)(shippingPath);
        const incoterm = !showIncotermOptions ? _incoterm.default.DOMESTIC : _incoterm.default.DDU;
        this.set('shipment.incoterm', incoterm);
        this.set('shipment.allowIncotermCustomization', showIncotermOptions);
        this.set('showIncotermOptions', showIncotermOptions);
        this.setShippingPath(shippingPath);
      } catch (e) {
        _browserRum.datadogRum.addError(e);

        this.set('shipment.incoterm', _incoterm.default.DDU);
      }
    },

    searchProducts: (0, _emberConcurrency.task)(function* (value) {
      yield (0, _emberConcurrency.timeout)(250);
      const {
        products
      } = yield _product.default.find({
        'boutiques.boutique': this.boutique._id,
        name: {
          $regex: value,
          $options: 'i'
        },
        limit: 6,
        fields: '_id,name,brand,images,local_price'
      });
      return products;
    }).restartable(),
    actions: {
      selectSearchProduct(item, product) {
        Ember.set(item, 'name', product.name);
        Ember.set(item, 'priceInclVat', product.local_price.amount);
      },

      addShipmentItem() {
        this.shipment.addItem({
          currency: this.currency,
          quantity: 1
        });
        (0, _validations.validateItems)(this.shipment.items);
      },

      removeShipmentItem(item) {
        this.shipment.items.removeObject(item);
        (0, _validations.validateItems)(this.shipment.items);
      },

      onChangeItemQuantity(item, quantity) {
        Ember.set(item, 'quantity', quantity);
      },

      setIncotermToDDU() {
        this.set('shipment.incoterm', _incoterm.default.DDU);
      },

      setIncotermToDDP() {
        this.set('shipment.incoterm', _incoterm.default.DDP);
      },

      changeProtectionCover(cover) {
        Ember.set(this, 'selectedProtectionCover', cover);

        if (cover === _shipmentProtectionCover.default.STANDARD) {
          Ember.set(this.shipment, 'insurance', undefined);
          Ember.set(this.shipment, 'accepts_insurance_terms', undefined);
          return;
        }

        Ember.set(this.shipment, 'insurance', {
          amount: null,
          currency: this.currency
        });
        Ember.set(this.shipment, 'accepts_insurance_terms', false);
      }

    }
  });

  _exports.default = _default;
});