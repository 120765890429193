define("flaneur/pods/components/insights-educational-modal/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class InsightsEducationalModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "image", {
        url: 'https://assets.trouva.com/image/upload/v1619706579/Retail%20Panel/insights/header-educational-modal.jpg',
        name: 'Introducing: Restock Opportunities'
      });
    }

    get styleNamespace() {
      return _podNames.default['insights-educational-modal'];
    }

  }

  _exports.default = InsightsEducationalModalComponent;
});