define("flaneur/pods/limited-access/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Km04PSQb",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"notify\"],[[35,4]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"lp-dots\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"lp-header\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"route\"],[\"lp-logo\",\"index\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,\"src\",\"https://res.cloudinary.com/streethub/image/upload/c_scale,h_75/v1517223377/main/Trouva_2018_2x.png\"],[14,\"alt\",\"Trouva logo\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"class\",\"route\"],[\"button button--secondary lp-login\",\"login\"]],[[\"default\"],[{\"statements\":[[2,\"      Login\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,0,[\"isLoggedIn\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[35,0,[\"isRetailer\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"log\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"log-container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"log-form\"],[12],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"title\"],[12],[2,\"Email not recognised\"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"We can’t find an account associated with \"],[1,[35,0,[\"user\",\"email\"]]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"log-form-login-again\"],[12],[2,\"Please try to \"],[6,[37,1],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"login\"]],\"parameters\":[]}]]],[2,\" again, using the email address associated with your Retail Panel.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"h1\"],[12],[2,\"\\n    You are currently not logged in.\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"link-to\",\"not\",\"if\",\"notify\",\"nav-bar-alert\"]}",
    "moduleName": "flaneur/pods/limited-access/template.hbs"
  });

  _exports.default = _default;
});