define("flaneur/pods/components/insights-educational-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ozfQ3Ibv",
    "block": "{\"symbols\":[\"@closeModal\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@containerClass\",\"@translucentOverlay\",\"@onClose\"],[[31,[\"educational-modal \",[32,0,[\"styleNamespace\"]]]],true,[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__header\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,[36,1],[[35,0,[\"url\"]],\"c_fill,g_auto,w_720,h_240,q_auto\"],null]],[15,\"alt\",[34,0,[\"name\"]]],[12],[13],[2,\"\\n\\n    \"],[11,\"div\"],[24,0,\"ember-modal-dialog__close\"],[4,[38,2],[\"click\",[32,1]],null],[12],[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"times\",\"fal\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ember-modal-dialog__body__header\"],[12],[2,\"\\n      \"],[8,\"new-label\",[],[[],[]],null],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Introducing: Restock Opportunities\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"Plan your supply with greater confidence, with the help of our new sales forecasts. We’ve leveraged your historic sales data to anticipate which products could continue to drive sales if re-stocked.\"],[13],[2,\"\\n\\n    \"],[8,\"button\",[],[[\"@onClick\"],[[30,[36,3],[[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Close\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"image\",\"cloudinary-image\",\"on\",\"fn\"]}",
    "moduleName": "flaneur/pods/components/insights-educational-modal/template.hbs"
  });

  _exports.default = _default;
});