define("flaneur/pods/brand/boutiques/boutique/products/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GkrzSZpl",
    "block": "{\"symbols\":[\"previousRoute\",\"button\"],\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,5],[\"calculatePreviousRoute\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"heading\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[[32,1],[30,[36,0],[[32,0,[\"brand\"]],[32,0,[\"brand\",\"currentBoutique\",\"_id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"long-arrow-left\",\"far\"]],null],[2,\"\\n      \"],[1,[30,[36,3],[\"Back to \",[30,[36,2],[[30,[36,1],[[32,1],\"brand.boutiques.boutique.inventory\"],null],\"Inventory\",\"Products\"],null]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"h1\"],[14,0,\"title\"],[12],[2,\"Edit product\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"form-flows/product\",[],[[\"@product\",\"@curationNotes\",\"@model\",\"@previousRoute\",\"@imagesChanged\",\"@resolveAmendment\"],[[34,4,[\"product\"]],[34,4,[\"curationNotes\"]],[34,4],[32,1],[30,[36,5],[\"imagesChanged\"],null],[30,[36,5],[\"resolveAmendment\",[35,4,[\"product\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"product-save\"],[12],[2,\"\\n      \"],[8,\"button\",[],[[\"@class\",\"@onClick\"],[\"product-save__btn\",[30,[36,6],[[32,0],[30,[36,5],[\"onSave\"],null],[35,4,[\"product\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,2,[\"loading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          Saving... \"],[8,[32,2,[\"spinner\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          Save product\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"array\",\"eq\",\"if\",\"concat\",\"model\",\"route-action\",\"action\",\"compute\",\"let\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/products/edit/template.hbs"
  });

  _exports.default = _default;
});