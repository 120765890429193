define("flaneur/pods/components/packaging-how-to/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I57CwKaP",
    "block": "{\"symbols\":[],\"statements\":[[2,\" \"],[10,\"div\"],[15,0,[31,[\"packaging-how-to \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card__step\"],[12],[2,\"1\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card__title\"],[12],[2,\"Select your products and quantities\"],[13],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"https://res.cloudinary.com/streethub/image/upload/v1585825157/Retail%20Panel/packaging/packaging.png\"],[14,\"alt\",\"box\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card__step\"],[12],[2,\"2\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card__title\"],[12],[2,\"Place your order through the retail panel\"],[13],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"https://res.cloudinary.com/streethub/image/upload/v1585825157/Retail%20Panel/packaging/payments.png\"],[14,\"alt\",\"computer\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card__step\"],[12],[2,\"3\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card__title\"],[12],[2,\"We will email your itemised order confirmation\"],[13],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"https://res.cloudinary.com/streethub/image/upload/v1585825157/Retail%20Panel/packaging/email.png\"],[14,\"alt\",\"envelop\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/packaging-how-to/template.hbs"
  });

  _exports.default = _default;
});