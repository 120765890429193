define("flaneur/couriers-data/easypost-couriers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const couriers = {
    UPS: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://res.cloudinary.com/streethub/image/upload/v1531306892/Retail%20Panel/Couriers/UPS_2x.png'
    },
    DHLExpress: {
      picked_up_by: 'dhl',
      courier_to_display: 'DHL',
      logo: 'https://res.cloudinary.com/streethub/image/upload/v1531324896/Retail%20Panel/Couriers/dhl_logo.gif'
    }
  };
  const defaultCourier = {
    picked_up_by: 'other',
    courier_to_display: 'other'
  };

  const getById = id => {
    return couriers[id] || defaultCourier;
  };

  var _default = _objectSpread(_objectSpread({}, couriers), {}, {
    getById
  });

  _exports.default = _default;
});