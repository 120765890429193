define("flaneur/pods/components/pages/receipt/order-page/component", ["exports", "@glimmer/component", "flaneur/enums/order-channel"], function (_exports, _component, _orderChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * Necessary due to assets' fingerprinting mechanism
   * that **doesn't** support dynamic asset filename such as
   * `/assets/made-receipt-qr-code-${store}.png`
   */
  const QR_CODE_SRCS = {
    CH: '/assets/made-receipt-qr-code-ch.png',
    DE: '/assets/made-receipt-qr-code-de.png',
    ES: '/assets/made-receipt-qr-code-es.png',
    FR: '/assets/made-receipt-qr-code-fr.png',
    GB: '/assets/made-receipt-qr-code-gb.png',
    NL: '/assets/made-receipt-qr-code-nl.png'
  };
  let PagesReceiptOrderPageComponent = (_dec = Ember._action, (_class = class PagesReceiptOrderPageComponent extends _component.default {
    get isGiftSimpleOrder() {
      const {
        order,
        reservations
      } = this.args.model;

      if (!order.gift) {
        return false;
      }

      if (!reservations[0].gift) {
        return true;
      }

      return !reservations[0].gift.message;
    }

    get isGiftMessageOrder() {
      const {
        order,
        reservations
      } = this.args.model;
      return order.gift && reservations[0].gift.message;
    }

    get isMadeOrder() {
      return this.args.model.order.channel === _orderChannel.default.MADE_DOT_COM;
    }

    get logo() {
      return !this.isMadeOrder ? 'https://res.cloudinary.com/streethub/image/upload/c_scale,h_75/v1517223377/main/Trouva_2018_2x.png' : '/assets/made-logo-black.png';
    }

    get qrCodeSrc() {
      const {
        store = 'gb'
      } = this.args.model.order.channel_specific;
      return QR_CODE_SRCS[store === null || store === void 0 ? void 0 : store.toUpperCase()];
    }

    onDidInsert() {
      const main = document.getElementById('main');
      main === null || main === void 0 ? void 0 : main.classList.remove('main');
      const outletContainer = document.getElementById('outlet-container');

      if (!outletContainer) {
        return;
      }

      outletContainer.style.overflowY = 'unset';
      outletContainer.style.padding = 'unset';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype)), _class));
  _exports.default = PagesReceiptOrderPageComponent;
});