define("flaneur/pods/components/empty-page/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['page'],
    imageUrl: Ember.computed('image', 'media.isSmall', function () {
      const imagePrefix = 'https://assets.trouva.com/image/upload/v1602580933/Retail%20Panel/empty-pages';
      return `${imagePrefix}/${this.image}${this.media.isSmall ? '-mobile' : ''}.png`;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.disableHorizontalScroll();
    },

    willDestroyElement() {
      this._super(...arguments);

      this.enableHorizontalScroll();
    },

    enableHorizontalScroll() {
      const outlet = document.getElementById('outlet-container');

      if (!outlet || this.media.isXlarge) {
        return;
      }

      outlet.style.overflowX = 'visible';
    },

    disableHorizontalScroll() {
      const outlet = document.getElementById('outlet-container');

      if (!outlet || this.media.isXlarge) {
        return;
      }

      outlet.style.overflowX = 'hidden';
    }

  });

  _exports.default = _default;
});