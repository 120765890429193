define("flaneur/utils/products-bulk-upload/mappers", ["exports", "flaneur/models/manufacturer", "flaneur/models/stock-line", "flaneur/models/variant", "flaneur/util/escape-regex", "flaneur/config/environment", "flaneur/utils/cloudinary-upload", "flaneur/utils/products-bulk-upload/validations"], function (_exports, _manufacturer, _stockLine, _variant, _escapeRegex, _environment, _cloudinaryUpload, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.imagesMapper = _exports.productMapper = _exports.stockLineMapper = _exports.MARKED_AS_UNBRANDED = void 0;
  const MARKED_AS_UNBRANDED = '<unbranded>';
  _exports.MARKED_AS_UNBRANDED = MARKED_AS_UNBRANDED;
  const resourcesCache = {
    manufacturers: new Map(),
    variants: new Map()
  };
  const cloudinaryUpload = new _cloudinaryUpload.default((0, _cloudinaryUpload.getCloudinaryConfig)());

  const stringToInteger = value => {
    return typeof value === 'string' ? parseInt(value === null || value === void 0 ? void 0 : value.replace(/^\D+/g, '')) : value;
  };

  const variantMapper = async (stockLine, variantValue, variantName) => {
    if (Ember.isEmpty(variantValue)) {
      return;
    }

    if (typeof variantValue === 'number') {
      variantValue = variantValue.toString();
    }

    const variantKey = `${variantName} | ${variantValue}`;
    const cached = resourcesCache.variants.get(variantKey);

    if (cached) {
      return stockLine.variants.addObject(cached);
    }

    const variants = await _variant.default.find({
      name: variantName,
      value: {
        $regex: `^${(0, _escapeRegex.default)(variantValue)}$`,
        $options: 'i'
      },
      fields: ['_id', 'value', 'name']
    });

    if (Ember.isEmpty(variants)) {
      return stockLine.variants.addObject({
        _id: _validations.RESOURCE_NOT_FOUND,
        name: variantName,
        value: variantValue
      });
    }

    const [fetchedVariant] = variants;
    resourcesCache.variants.set(variantKey, fetchedVariant);
    stockLine.variants.addObject(fetchedVariant);
  };

  const stockLineMapper = {
    quantity: (stockLine, quantity) => stockLine.quantity = quantity,
    sku: async (stockLine, sku, boutique) => {
      if (Ember.isEmpty(sku)) {
        stockLine.sku = null;
        return;
      }

      if (typeof sku === 'number') {
        sku = sku.toString();
      }

      const {
        meta: {
          total
        }
      } = await _stockLine.default.find({
        boutique: boutique._id,
        sku,
        fields: ['_id']
      });
      stockLine.sku = {
        value: sku,
        status: total > 0 ? _validations.RESOURCE_ALREADY_EXISTS : null
      };
    },
    care_instructions: (stockLine, careInstructions) => stockLine.care_instructions = careInstructions,
    'dimensions.length': (stockLine, length) => stockLine.dimensions.length = stringToInteger(length),
    'dimensions.width': (stockLine, width) => stockLine.dimensions.width = stringToInteger(width),
    'dimensions.height': (stockLine, height) => stockLine.dimensions.height = stringToInteger(height),
    size: (stockLine, size) => variantMapper(stockLine, size, 'size'),
    material: (stockLine, size) => variantMapper(stockLine, size, 'material'),
    color: (stockLine, size) => variantMapper(stockLine, size, 'color'),
    other: (stockLine, size) => variantMapper(stockLine, size, 'other')
  };
  /**
   * The order matters, in order to access already populated fields, e.g.
   * in order to access "brand" in "images" mapper, just place the later
   * mapper after the first.
   */

  _exports.stockLineMapper = stockLineMapper;
  const productMapper = {
    brand: async (product, brand) => {
      if (Ember.isEmpty(brand)) {
        product.standard_manufacturer = {
          _id: null
        };
        product.unbranded = null;
        return;
      }

      if (brand === MARKED_AS_UNBRANDED) {
        product.standard_manufacturer = {
          _id: null
        };
        product.unbranded = true;
        return;
      }

      const cached = resourcesCache.manufacturers.get(brand);

      if (cached) {
        product.standard_manufacturer = cached;
        product.unbranded = false;
        return;
      }

      const regex = `^${(0, _escapeRegex.default)(brand)}$`;
      const [manufacturer] = await _manufacturer.default.find({
        $or: [{
          name: {
            $regex: regex,
            $options: 'i'
          }
        }, {
          alternate_names: {
            $regex: regex,
            $options: 'i'
          }
        }],
        fields: ['_id', 'name']
      });

      if (Ember.isEmpty(manufacturer)) {
        product.standard_manufacturer = {
          _id: _validations.RESOURCE_NOT_FOUND,
          name: brand
        };
        product.unbranded = false;
        return;
      }

      resourcesCache.manufacturers.set(brand, manufacturer);
      product.standard_manufacturer = manufacturer;
      product.unbranded = false;
    },
    name: (product, name) => product.name = name,
    description: (product, description) => product.description = description,
    price: (product, amount, {
      currency
    }) => {
      const price = typeof amount === 'string' ? parseFloat(amount.replace(/,/g, '.').replace(/[£€$]/g, '')) : amount;
      const localPrice = {
        amount: Math.round(price * 100),
        currency
      };
      product.local_price = localPrice;
    },
    gtin: (product, gtin) => {
      if (typeof gtin === 'number') {
        gtin = gtin.toString();
      }

      product.gtin = gtin;
    },
    images: async (product, images) => {
      if (Ember.isEmpty(images)) {
        product.images = [];
        return;
      }

      const allImages = images.split(/\s+/).uniq().filter(image => Ember.isPresent(image));
      product.images.addObjects(allImages);
    }
  };
  _exports.productMapper = productMapper;

  const imagesMapper = async product => {
    if (Ember.isEmpty(product.images)) {
      return;
    }

    try {
      const formData = {
        folder: `brand/${product.brand}`,
        tags: `app:${_environment.default.APP.name},browser_uploads`
      };
      const uploadImagesPromises = product.images.map(image => cloudinaryUpload.upload(image, formData));
      const uploadedImages = await Ember.RSVP.map(uploadImagesPromises, _cloudinaryUpload.convertImageFromCloudinaryToPromenade);
      product.images.setObjects(uploadedImages);
    } catch (e) {
      console.error(e);
    }
  };

  _exports.imagesMapper = imagesMapper;
});