define("flaneur/pods/components/pages/welcome/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fmL3dG54",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container__details\"],[12],[2,\"\\n     \"],[10,\"h1\"],[12],[2,\"Welcome to Trouva\"],[13],[2,\"\\n     \"],[10,\"h3\"],[12],[2,\"Drive sales, manage inventory, and bring your in-store experience online\"],[13],[2,\"\\n     \"],[10,\"p\"],[12],[2,\"Follow our simple checklist to get live on Trouva and start selling your beautiful inventory to customers from around the world.\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"container__welcome-image\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"https://res.cloudinary.com/streethub/image/upload/v1571135835/Retail%20Panel/Onboarding/WelcomeTrouvaBoutique.png\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"container__button\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@onClick\"],[[30,[36,0],[[32,0],\"goToSettings\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Get started\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"container__progress\"],[12],[2,\"\\n    \"],[8,\"boutique-setup-progress\",[],[[\"@boutique\",\"@onComplete\"],[[34,1],[30,[36,0],[[32,0],\"goToSettings\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"boutique\"]}",
    "moduleName": "flaneur/pods/components/pages/welcome/template.hbs"
  });

  _exports.default = _default;
});